p {
  margin-top: 0.25em;
}

img { 
  max-width: 100%;
}

.hotel-name,
.hotel-name input {
  font-size: 2em;
  font-weight: bold;
}

.hotel-name {
  margin-bottom: 1em;
}

.hotel-summary,
.hotel-summary input {
  font-size: 20px;
  line-height: 1.5;
}

.hotel-summary {
  margin-bottom: 2em;
}

.hotel-contacts {
  padding: 20px;
}

.offer-title,
.offer-title input {
  font-size: 2em;
  font-weight: bold;
}

.offer-title {
  margin-top: 0;
  margin-bottom: 0.5em;
}

.offer-description,
.offer-description input {
  font-size: 15px;
  line-height: 1.5;
}

.offer-description {
  margin-bottom: 1em;
}


/* Preview */
.preview .container {
  max-width: 940px;
  margin: 0 auto;
  padding: 0 20px;
}

.preview .cover-image {
  height: 40vw;
}


@media screen and (max-width: 767px) {
  .preview .cover-image {
    height: 60vw;
  }
}

.preview section {
  padding-bottom: 20px;
}

@media screen and (max-width: 991px) {
  .preview .container {
    max-width: 728px;
  }
}

@media screen and (max-width: 767px) {
  .preview .container {
    padding: 12px 20px;
  }
}

.preview .heading3 {
  margin-bottom: 20px;
  font-size: 28px;
}
.preview .info-row {
  display: flex;
  font-size: 20px;
  line-height: 25px;
  margin-bottom: 10px;
}

.preview .info-row .info {
  flex: 0 300px;  
  font-weight: 700;
}

.preview .offer {
  display: flex;
  flex-wrap: wrap;
  margin-left: -12px;
  margin-right: -12px;
}

.preview .offer > div {
  padding: 0 12px;
}

.preview .offer-image-wrapper {
  flex: 1 0 300px;
}


@media screen and (max-width: 767px) {
  .preview .offer {
    margin: 0;
  }

  .preview .offer > div {
    padding: 0;
  }

  .preview .offer-image-wrapper {
    flex: 1 100%;
  }

  .preview .info-row {
    font-size: 16px;
  }

  .preview .info-row .info {
    flex: 0 200px;  
  }
  
}

.preview .offer-image {
  margin-bottom: 12px;
}
